<template>
  <div id="testdemo">
    <div ref="remotewrap" class="remote-s">
      <video class="remote-video" poster="@/assets/back.jpg" id="remote-video" muted autoplay playsinline></video>
    </div>
    <div class="options">
      
    </div>
  </div>
</template>
<script>
export default {
  methods: {},
};
</script>
<style lang='scss' scoped>
@import "./index.scss";
</style>